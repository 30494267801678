import React, { useState } from 'react';

const FormComponent = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    telefono: '',
    correoElectronico: '',
    direccion: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Handle form submission, e.g., send data to a server
  };

  return (
    <div className="App">
        <header className="App-header">
            <div className="container">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                    <label htmlFor="nombre" className="form-label">Nombre*</label>
                    <input
                        type="text"
                        className="form-control"
                        id="nombre"
                        name="nombre"
                        value={formData.nombre}
                        onChange={handleChange}
                        required
                    />
                    </div>
                    <div className="mb-3">
                    <label htmlFor="apellidoPaterno" className="form-label">Apellido Paterno*</label>
                    <input
                        type="text"
                        className="form-control"
                        id="apellidoPaterno"
                        name="apellidoPaterno"
                        value={formData.apellidoPaterno}
                        onChange={handleChange}
                        required
                    />
                    </div>
                    <div className="mb-3">
                    <label htmlFor="apellidoMaterno" className="form-label">Apellido Materno*</label>
                    <input
                        type="text"
                        className="form-control"
                        id="apellidoMaterno"
                        name="apellidoMaterno"
                        value={formData.apellidoMaterno}
                        onChange={handleChange}
                        required
                    />
                    </div>
                    <div className="mb-3">
                    <label htmlFor="telefono" className="form-label">Telefono</label>
                    <input
                        type="text"
                        className="form-control"
                        id="telefono"
                        name="telefono"
                        value={formData.telefono}
                        onChange={handleChange}
                    />
                    </div>
                    <div className="mb-3">
                    <label htmlFor="correoElectronico" className="form-label">Correo Electronico*</label>
                    <input
                        type="email"
                        className="form-control"
                        id="correoElectronico"
                        name="correoElectronico"
                        value={formData.correoElectronico}
                        onChange={handleChange}
                        required
                    />
                    </div>
                    <div className="mb-3">
                    <label htmlFor="direccion" className="form-label">Direccion</label>
                    <input
                        type="text"
                        className="form-control"
                        id="direccion"
                        name="direccion"
                        value={formData.direccion}
                        onChange={handleChange}
                    />
                    </div>
                    <button type="submit" className="button">Submit</button>
                </form>
            </div>
        </header>
    </div>
  );
};

export default FormComponent;
