import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

const Welcome = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);
        } else {
            setUserInfo(authState.idToken.claims);
            // You can also get user information from the `/userinfo` endpoint
            /*oktaAuth.getUser().then((info) => {
                setUserInfo(info);
            });*/

        }
    }, [authState, oktaAuth]); // Update if authState changes

    if (!userInfo) {
        return (
            <div>
                <p>Fetching user profile...</p>
            </div>
        );
    }

    return (
        <div class="Welcome">
            <svg width="600" height="100" viewBox="0 0 600 100" xmlns="http://www.w3.org/2000/svg" id="loading" stroke="black" stroke-width="1">
                <path d="M3.79999 39C3.79999 32.1333 5.29999 26 8.29999 20.6C11.3 15.1333 15.4 10.9 20.6 7.89999C25.8 4.83332 31.6 3.29999 38 3.29999C45.8 3.29999 52.4667 5.16665 58 8.89999C63.6 12.5667 67.6667 17.7667 70.2 24.5H62C60 19.7667 56.9333 16.0667 52.8 13.4C48.7333 10.7333 43.8 9.39999 38 9.39999C32.8667 9.39999 28.2333 10.6 24.1 13C20.0333 15.4 16.8333 18.8667 14.5 23.4C12.1667 27.8667 11 33.0667 11 39C11 44.9333 12.1667 50.1333 14.5 54.6C16.8333 59.0667 20.0333 62.5 24.1 64.9C28.2333 67.3 32.8667 68.5 38 68.5C43.8 68.5 48.7333 67.2 52.8 64.6C56.9333 61.9333 60 58.2667 62 53.6H70.2C67.6667 60.2667 63.6 65.4333 58 69.1C52.4 72.7667 45.7333 74.6 38 74.6C31.6 74.6 25.8 73.1 20.6 70.1C15.4 67.0333 11.3 62.8 8.29999 57.4C5.29999 52 3.79999 45.8667 3.79999 39Z" />
                <path d="M80.9484 46.6C80.9484 41 82.0484 36.1 84.2484 31.9C86.5151 27.6333 89.6151 24.3667 93.5484 22.1C97.5484 19.7667 102.048 18.6 107.048 18.6C112.248 18.6 116.715 19.8 120.448 22.2C124.248 24.6 126.982 27.6666 128.648 31.4V19.4H135.648V74H128.648V61.9C126.915 65.6333 124.148 68.7333 120.348 71.2C116.615 73.6 112.148 74.8 106.948 74.8C102.015 74.8 97.5484 73.6333 93.5484 71.3C89.6151 68.9667 86.5151 65.6667 84.2484 61.4C82.0484 57.1333 80.9484 52.2 80.9484 46.6ZM128.648 46.7C128.648 42.3 127.748 38.4333 125.948 35.1C124.148 31.7667 121.682 29.2 118.548 27.4C115.482 25.6 112.082 24.7 108.348 24.7C104.482 24.7 101.015 25.5667 97.9484 27.3C94.8818 29.0333 92.4484 31.5667 90.6484 34.9C88.9151 38.1667 88.0484 42.0667 88.0484 46.6C88.0484 51.0667 88.9151 55 90.6484 58.4C92.4484 61.7333 94.8818 64.3 97.9484 66.1C101.015 67.8333 104.482 68.7 108.348 68.7C112.082 68.7 115.482 67.8 118.548 66C121.682 64.2 124.148 61.6333 125.948 58.3C127.748 54.9667 128.648 51.1 128.648 46.7Z" />
                <path d="M158.529 31.4C160.262 27.7333 162.996 24.7 166.729 22.3C170.529 19.8333 175.029 18.6 180.229 18.6C185.162 18.6 189.596 19.7667 193.529 22.1C197.529 24.3667 200.629 27.6333 202.829 31.9C205.096 36.1 206.229 41 206.229 46.6C206.229 52.2 205.096 57.1333 202.829 61.4C200.629 65.6667 197.529 68.9667 193.529 71.3C189.596 73.6333 185.162 74.8 180.229 74.8C175.096 74.8 170.629 73.6 166.829 71.2C163.029 68.7333 160.262 65.6667 158.529 62V99.8H151.629V19.4H158.529V31.4ZM199.129 46.6C199.129 42.0667 198.229 38.1667 196.429 34.9C194.696 31.5667 192.296 29.0333 189.229 27.3C186.162 25.5667 182.696 24.7 178.829 24.7C175.096 24.7 171.662 25.6 168.529 27.4C165.462 29.2 163.029 31.7667 161.229 35.1C159.429 38.4333 158.529 42.3 158.529 46.7C158.529 51.1 159.429 54.9667 161.229 58.3C163.029 61.6333 165.462 64.2 168.529 66C171.662 67.8 175.096 68.7 178.829 68.7C182.696 68.7 186.162 67.8333 189.229 66.1C192.296 64.3 194.696 61.7333 196.429 58.4C198.229 55 199.129 51.0667 199.129 46.6Z" />
                <path d="M228.209 25.3V59.2C228.209 62.5333 228.843 64.8333 230.109 66.1C231.376 67.3667 233.609 68 236.809 68H243.209V74H235.709C230.776 74 227.109 72.8667 224.709 70.6C222.309 68.2667 221.109 64.4667 221.109 59.2V25.3H213.509V19.4H221.109V5.69999H228.209V19.4H243.209V25.3H228.209Z" />
                <path d="M302.054 19.4V74H295.054V64.4C293.454 67.8 290.987 70.4 287.654 72.2C284.321 74 280.587 74.9 276.454 74.9C269.921 74.9 264.587 72.9 260.454 68.9C256.321 64.8333 254.254 58.9667 254.254 51.3V19.4H261.154V50.5C261.154 56.4333 262.621 60.9667 265.554 64.1C268.554 67.2333 272.621 68.8 277.754 68.8C283.021 68.8 287.221 67.1333 290.354 63.8C293.487 60.4667 295.054 55.5667 295.054 49.1V19.4H302.054Z" />
                <path d="M325.035 29.1C326.568 25.7 328.902 23.0667 332.035 21.2C335.235 19.3333 339.135 18.4 343.735 18.4V25.7H341.835C336.768 25.7 332.702 27.0667 329.635 29.8C326.568 32.5333 325.035 37.1 325.035 43.5V74H318.035V19.4H325.035V29.1Z" />
                <path d="M394.766 57.4H362.966L356.866 74H349.466L374.966 4.99998H382.866L408.266 74H400.866L394.766 57.4ZM392.666 51.6L378.866 13.8L365.066 51.6H392.666Z" />
                <path d="M426.4 31.4C428.133 27.7333 430.867 24.7 434.6 22.3C438.4 19.8333 442.9 18.6 448.1 18.6C453.033 18.6 457.467 19.7667 461.4 22.1C465.4 24.3667 468.5 27.6333 470.7 31.9C472.967 36.1 474.1 41 474.1 46.6C474.1 52.2 472.967 57.1333 470.7 61.4C468.5 65.6667 465.4 68.9667 461.4 71.3C457.467 73.6333 453.033 74.8 448.1 74.8C442.967 74.8 438.5 73.6 434.7 71.2C430.9 68.7333 428.133 65.6667 426.4 62V99.8H419.5V19.4H426.4V31.4ZM467 46.6C467 42.0667 466.1 38.1667 464.3 34.9C462.567 31.5667 460.167 29.0333 457.1 27.3C454.033 25.5667 450.567 24.7 446.7 24.7C442.967 24.7 439.533 25.6 436.4 27.4C433.333 29.2 430.9 31.7667 429.1 35.1C427.3 38.4333 426.4 42.3 426.4 46.7C426.4 51.1 427.3 54.9667 429.1 58.3C430.9 61.6333 433.333 64.2 436.4 66C439.533 67.8 442.967 68.7 446.7 68.7C450.567 68.7 454.033 67.8333 457.1 66.1C460.167 64.3 462.567 61.7333 464.3 58.4C466.1 55 467 51.0667 467 46.6Z" />
                <path d="M493.88 31.4C495.614 27.7333 498.347 24.7 502.08 22.3C505.88 19.8333 510.38 18.6 515.58 18.6C520.514 18.6 524.947 19.7667 528.88 22.1C532.88 24.3667 535.98 27.6333 538.18 31.9C540.447 36.1 541.58 41 541.58 46.6C541.58 52.2 540.447 57.1333 538.18 61.4C535.98 65.6667 532.88 68.9667 528.88 71.3C524.947 73.6333 520.514 74.8 515.58 74.8C510.447 74.8 505.98 73.6 502.18 71.2C498.38 68.7333 495.614 65.6667 493.88 62V99.8H486.98V19.4H493.88V31.4ZM534.48 46.6C534.48 42.0667 533.58 38.1667 531.78 34.9C530.047 31.5667 527.647 29.0333 524.58 27.3C521.514 25.5667 518.047 24.7 514.18 24.7C510.447 24.7 507.014 25.6 503.88 27.4C500.814 29.2 498.38 31.7667 496.58 35.1C494.78 38.4333 493.88 42.3 493.88 46.7C493.88 51.1 494.78 54.9667 496.58 58.3C498.38 61.6333 500.814 64.2 503.88 66C507.014 67.8 510.447 68.7 514.18 68.7C518.047 68.7 521.514 67.8333 524.58 66.1C527.647 64.3 530.047 61.7333 531.78 58.4C533.58 55 534.48 51.0667 534.48 46.6Z" />
            </svg>
            <p>Bienvenido a mi app</p>
            <p>{userInfo.name}</p>            
        </div>
        
    );
};

export default Welcome;