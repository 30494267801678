import React, { Component } from 'react';
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';
import Home from './Home';
import Profile from './Profile';
import Welcome from './Welcome';
import FormComponent from './Form';


const oktaAuth = new OktaAuth({
  issuer: 'https://login.capturapp.com/oauth2/default',
  clientId: '0oa6cqd2x41sFsfPy5d7',
  redirectUri: window.location.origin + '/login/callback',
  userinfoUrl: 'https://login.capturapp.com/oauth2/default/v1/userinfo'
});


class App extends Component {

  constructor(props) {
    super(props);
    this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
      props.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };
  }

  render() {
    return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
        <Route path="/" exact={true} component={Home}/>
        <Route path="/login/callback" exact={true} component={LoginCallback}/>
        <SecureRoute path="/profile" exact={true} component={Profile}/>
        <SecureRoute path="/welcome" exact={true} component={Welcome}/>
        <SecureRoute path="/captura" exact={true} component={FormComponent}/>
      </Security>
    );
  }
}

const AppWithRouterAccess = withRouter(App);

class RouterApp extends Component {
  render() {
    return (<Router><AppWithRouterAccess/></Router>);
  }
}

export default RouterApp;
