import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import './App.css';

export default withOktaAuth(class Home extends Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  async login() {
    await this.props.oktaAuth.signInWithRedirect();
  }

  async logout() {
    await this.props.oktaAuth.signOut();
  }

  render() {
    let body = null;

    if (this.props.authState?.isAuthenticated) {
      var userName = this.props.authState.idToken.claims.name;
      var groups = this.props.authState.idToken.claims?.groups;
      if (groups){
      var groupStr = groups.map((group) => 
      {
        return(<button class="button">{group}</button>)
      });}

      body = (
        <>
          <p class="welcome">Bienvenido a CapturApp {userName}</p>
          {groupStr}
          <button class="button" onClick={this.logout}>Cerrar Sesion</button>
        </>
      );
    } else {
      body = (
        <>
          <p>Hola</p>
          <button class="button" onClick={this.login}>Iniciar Sesion!</button>
        </>
      );
    }

    return (
      <div className="App">
        <header className="App-header">
        
          <svg id="loading" width="186" height="137" viewBox="0 0 186 137" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path stroke="black" d="M77.551 27.523C80.176 28.08 80.768 27.805 81.796 25.548C82.856 23.222 83.247 23.058 85.246 24.098C86.486 24.743 87.667 25.415 87.871 25.59C88.076 25.766 87.025 27.988 85.536 30.529C83.637 33.769 82.368 34.971 81.286 34.556C76.203 32.606 69.971 31.803 63.033 32.204C35.486 33.799 18.252 63.859 30.66 88.669C33.787 94.922 41.566 102.961 47.572 106.148C51.124 108.032 51.861 108.876 51.366 110.493C50.409 113.62 50.21 113.624 44.511 110.64C38.198 107.335 29.197 98.263 25.973 91.955C16.519 73.463 20.696 51.496 36.356 37.347C47.116 27.625 61.67 24.154 77.551 27.523Z" />
            <path stroke="black" d="M98.068 40.863C86.968 63.059 78.025 81.358 78.195 81.528C78.365 81.698 79.628 82.118 81.002 82.461C84.5 82.461 83 83 100.5 48.962C109.85 30.195 117.83 14.629 118.233 14.372C118.876 13.96 168.484 112.049 174.561 125.75L176.89 131H168.447H160.005L139.252 89.518L118.5 48.037L97.706 89.518L76.912 131L65.206 130.974C55.758 130.953 52.235 130.517 46.942 128.713C37.325 125.434 29.685 120.419 21.979 112.324C-1.68401 87.471 -0.0390091 49.516 25.721 25.972C28.985 22.989 35.029 19.085 40.513 16.417C47.955 12.797 51.8652 11.2148 51.5 10.5C50 7.564 50.879 6.436 48 7.5C42.832 8.603 30.842 14.735 24.746 19.519C13.952 27.991 5.04799 41.777 1.53199 55.46C-0.43001 63.092 -0.44101 78.841 1.50999 86.54C2.33999 89.818 4.88399 96.325 7.16299 101C10.442 107.728 12.993 111.18 19.403 117.559C28.409 126.523 35.298 130.812 46.634 134.512C53.146 136.638 56.125 137 67.1 137H79.945L99.106 98.763L118.267 60.526L137.377 98.763L156.488 137H171.244C179.36 137 185.975 136.662 185.945 136.25C185.915 135.838 170.671 105.127 152.071 68.004L118.251 0.507996L98.068 40.863Z" />
          </svg>

          <svg id="loading" width="540" height="98" viewBox="0 0 540 98" stroke="black" stroke-width="1" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 36.7C1 29.8333 2.5 23.7 5.5 18.3C8.50001 12.8333 12.6 8.60001 17.8 5.6C23 2.53333 28.8 1 35.2 1C43 1 49.6667 2.86666 55.2 6.6C60.8 10.2667 64.8667 15.4667 67.4 22.2H59.2C57.2 17.4667 54.1333 13.7667 50 11.1C45.9333 8.43331 41 7.1 35.2 7.1C30.0667 7.1 25.4333 8.30001 21.3 10.7C17.2333 13.1 14.0333 16.5667 11.7 21.1C9.36671 25.5667 8.20001 30.7667 8.20001 36.7C8.20001 42.6333 9.36671 47.8333 11.7 52.3C14.0333 56.7667 17.2333 60.2 21.3 62.6C25.4333 65 30.0667 66.2 35.2 66.2C41 66.2 45.9333 64.9 50 62.3C54.1333 59.6333 57.2 55.9667 59.2 51.3H67.4C64.8667 57.9667 60.8 63.1333 55.2 66.8C49.6 70.4667 42.9333 72.3 35.2 72.3C28.8 72.3 23 70.8 17.8 67.8C12.6 64.7333 8.50001 60.5 5.5 55.1C2.5 49.7 1 43.5667 1 36.7Z" />
            <path d="M78.1484 44.3C78.1484 38.7 79.2484 33.8 81.4484 29.6C83.7151 25.3333 86.8151 22.0667 90.7484 19.8C94.7484 17.4667 99.248 16.3 104.248 16.3C109.448 16.3 113.915 17.5 117.648 19.9C121.448 22.3 124.182 25.3666 125.848 29.1V17.1H132.848V71.7H125.848V59.6C124.115 63.3333 121.348 66.4333 117.548 68.9C113.815 71.3 109.348 72.5 104.148 72.5C99.215 72.5 94.7484 71.3333 90.7484 69C86.8151 66.6667 83.7151 63.3667 81.4484 59.1C79.2484 54.8333 78.1484 49.9 78.1484 44.3ZM125.848 44.4C125.848 40 124.948 36.1333 123.148 32.8C121.348 29.4667 118.882 26.9 115.748 25.1C112.682 23.3 109.282 22.4 105.548 22.4C101.682 22.4 98.215 23.2667 95.1484 25C92.0818 26.7333 89.6484 29.2667 87.8484 32.6C86.1151 35.8667 85.2484 39.7667 85.2484 44.3C85.2484 48.7667 86.1151 52.7 87.8484 56.1C89.6484 59.4333 92.0818 62 95.1484 63.8C98.215 65.5333 101.682 66.4 105.548 66.4C109.282 66.4 112.682 65.5 115.748 63.7C118.882 61.9 121.348 59.3333 123.148 56C124.948 52.6667 125.848 48.8 125.848 44.4Z" />
            <path d="M155.729 29.1C157.462 25.4333 160.196 22.4 163.929 20C167.729 17.5333 172.229 16.3 177.429 16.3C182.362 16.3 186.796 17.4667 190.729 19.8C194.729 22.0667 197.829 25.3333 200.029 29.6C202.296 33.8 203.429 38.7 203.429 44.3C203.429 49.9 202.296 54.8333 200.029 59.1C197.829 63.3667 194.729 66.6667 190.729 69C186.796 71.3333 182.362 72.5 177.429 72.5C172.296 72.5 167.829 71.3 164.029 68.9C160.229 66.4333 157.462 63.3667 155.729 59.7V97.5H148.829V17.1H155.729V29.1ZM196.329 44.3C196.329 39.7667 195.429 35.8667 193.629 32.6C191.896 29.2667 189.496 26.7333 186.429 25C183.362 23.2667 179.896 22.4 176.029 22.4C172.296 22.4 168.862 23.3 165.729 25.1C162.662 26.9 160.229 29.4667 158.429 32.8C156.629 36.1333 155.729 40 155.729 44.4C155.729 48.8 156.629 52.6667 158.429 56C160.229 59.3333 162.662 61.9 165.729 63.7C168.862 65.5 172.296 66.4 176.029 66.4C179.896 66.4 183.362 65.5333 186.429 63.8C189.496 62 191.896 59.4333 193.629 56.1C195.429 52.7 196.329 48.7667 196.329 44.3Z" />
            <path d="M225.409 23V56.9C225.409 60.2333 226.043 62.5333 227.309 63.8C228.576 65.0667 230.809 65.7 234.009 65.7H240.409V71.7H232.909C227.976 71.7 224.309 70.5667 221.909 68.3C219.509 65.9667 218.309 62.1667 218.309 56.9V23H210.709V17.1H218.309V3.4H225.409V17.1H240.409V23H225.409Z" />
            <path d="M299.254 17.1V71.7H292.254V62.1C290.654 65.5 288.187 68.1 284.854 69.9C281.521 71.7 277.787 72.6 273.654 72.6C267.121 72.6 261.787 70.6 257.654 66.6C253.521 62.5333 251.454 56.6667 251.454 49V17.1H258.354V48.2C258.354 54.1333 259.821 58.6667 262.754 61.8C265.754 64.9333 269.821 66.5 274.954 66.5C280.221 66.5 284.421 64.8333 287.554 61.5C290.687 58.1667 292.254 53.2667 292.254 46.8V17.1H299.254Z" />
            <path d="M322.235 26.8C323.768 23.4 326.102 20.7667 329.235 18.9C332.435 17.0333 336.335 16.1 340.935 16.1V23.4H339.035C333.968 23.4 329.902 24.7667 326.835 27.5C323.768 30.2333 322.235 34.8 322.235 41.2V71.7H315.235V17.1H322.235V26.8Z" />
            <path d="M391.966 55.1H360.166L354.066 71.7H346.666L372.166 2.69999H380.066L405.466 71.7H398.066L391.966 55.1ZM389.866 49.3L376.066 11.5L362.266 49.3H389.866Z" />
            <path d="M423.6 29.1C425.333 25.4333 428.067 22.4 431.8 20C435.6 17.5333 440.1 16.3 445.3 16.3C450.233 16.3 454.667 17.4667 458.6 19.8C462.6 22.0667 465.7 25.3333 467.9 29.6C470.167 33.8 471.3 38.7 471.3 44.3C471.3 49.9 470.167 54.8333 467.9 59.1C465.7 63.3667 462.6 66.6667 458.6 69C454.667 71.3333 450.233 72.5 445.3 72.5C440.167 72.5 435.7 71.3 431.9 68.9C428.1 66.4333 425.333 63.3667 423.6 59.7V97.5H416.7V17.1H423.6V29.1ZM464.2 44.3C464.2 39.7667 463.3 35.8667 461.5 32.6C459.767 29.2667 457.367 26.7333 454.3 25C451.233 23.2667 447.767 22.4 443.9 22.4C440.167 22.4 436.733 23.3 433.6 25.1C430.533 26.9 428.1 29.4667 426.3 32.8C424.5 36.1333 423.6 40 423.6 44.4C423.6 48.8 424.5 52.6667 426.3 56C428.1 59.3333 430.533 61.9 433.6 63.7C436.733 65.5 440.167 66.4 443.9 66.4C447.767 66.4 451.233 65.5333 454.3 63.8C457.367 62 459.767 59.4333 461.5 56.1C463.3 52.7 464.2 48.7667 464.2 44.3Z" />
            <path d="M491.08 29.1C492.814 25.4333 495.547 22.4 499.28 20C503.08 17.5333 507.58 16.3 512.78 16.3C517.714 16.3 522.147 17.4667 526.08 19.8C530.08 22.0667 533.18 25.3333 535.38 29.6C537.647 33.8 538.78 38.7 538.78 44.3C538.78 49.9 537.647 54.8333 535.38 59.1C533.18 63.3667 530.08 66.6667 526.08 69C522.147 71.3333 517.714 72.5 512.78 72.5C507.647 72.5 503.18 71.3 499.38 68.9C495.58 66.4333 492.814 63.3667 491.08 59.7V97.5H484.18V17.1H491.08V29.1ZM531.68 44.3C531.68 39.7667 530.78 35.8667 528.98 32.6C527.247 29.2667 524.847 26.7333 521.78 25C518.714 23.2667 515.247 22.4 511.38 22.4C507.647 22.4 504.214 23.3 501.08 25.1C498.014 26.9 495.58 29.4667 493.78 32.8C491.98 36.1333 491.08 40 491.08 44.4C491.08 48.8 491.98 52.6667 493.78 56C495.58 59.3333 498.014 61.9 501.08 63.7C504.214 65.5 507.647 66.4 511.38 66.4C515.247 66.4 518.714 65.5333 521.78 63.8C524.847 62 527.247 59.4333 528.98 56.1C530.78 52.7 531.68 48.7667 531.68 44.3Z" />
          </svg>

          {body}

        </header>
      </div>
    );
  }
});